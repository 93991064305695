import React from 'react'
import { Helmet } from 'react-helmet'
import Base, { Section } from '../../components/privacy-policy-base'

const updateData = {
  date: '09 October 2021',
  changes: [
    {
      section: 'Section 8. Data Deletion Request.',
      description:
        'Section added detailing the data deletion request and process',
    },
  ],
}

const PrivacyPolicyPage = (): JSX.Element => (
  <Base language="en" updateData={updateData}>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <meta name="description" content="HOKI Shop Taiwan privacy policy" />
    </Helmet>
    <Section>
      <h2>1. Introduction</h2>
      <div>
        In HOKI Shop Taiwan ("HOKI Shop", "we", or "us"), we want to provide the
        best possible service for you. To do this we need to gather some data,
        including personally identifying data, that are relevant with the
        services we provide. Even so, privacy and the security of your personal
        data will always be the outmost importance for us. To fulfill our
        objective, through this Privacy Policy ("Policy"), we will explain in
        detail how and why we collect, store, share, and use your personal data
        that you entrust to us.
      </div>
    </Section>
    <Section>
      <h2>2. About this Policy</h2>
      <div>
        This Policy explains in detail information related to collection,
        storage, sharing, and usage of personal data that we obtain from you.
        This Policy applies for all services provided by HOKI Shop.
      </div>
      <div>
        Should there are services that we offer with a separate privacy policy,
        that separate privacy policy will prevail, with provisions that are not
        covered will be covered by this Policy.
      </div>
      <div>
        If you have any question, complaints, or suggestion, you can see the
        section 'Contact Us' below. Or, if you do not agree with the content of
        this Policy, please remember that it is your option to use the services
        provided by HOKI Shop.
      </div>
    </Section>
    <Section>
      <h2>3. Personal data we collect from you</h2>
      <div>
        In order to provide our services, we may need you to provide us with
        personal information that are needed to perform the requested service.
        You have the right to not provide us the required information; however,
        in most cases, that means we may not be able to provide services which
        requires that information to you.
      </div>
      <div>
        Depending on which services you use, we collect these information:
      </div>
      <div>
        <h3>3.1 Information that you provided</h3>
        <div>
          We may collect personal information that you provided to us which are
          needed to provide services you requested. For example, in order to use
          our services, you provided us with your name, phone number, address,
          identity card number, email address, and your social media ID.
        </div>
        <h3>3.2 Information that we collect from your usage</h3>
        <div>
          We also collect information about your service usage. This information
          includes, but not limited to: application settings (region, language,
          timezone), application ID, application version, SDK version, operating
          system version, and application status (e.g. installing, updating,
          deleting). We also collect log information and your behavior from our
          server or third-party, including IP address, log information from
          usage of services (e.g. access time, registration time, activity
          time), and which pages and functionality you access.
        </div>
        <h3>3.3 Information from third-party partners</h3>
        <div>
          We also collect information from third-party partners about you,
          including from advertisers and partners who work with us so that we
          can provide services for you. We will use these personal information
          if you have given us or our partners consent to share your data or
          when we have legitimate reason for using that personal information in
          providing service to you.
        </div>
        <h3>3.4 Information which are not considered personal information</h3>
        <div>
          We may also collect other information that are not directly tied to
          your personal information or may not be defined as a personal
          information according to the local law. This type of information may
          include device ID, network monitoring data, page access activity, page
          access duration, etc.
        </div>
      </div>
    </Section>
    <Section>
      <h2>4. How we use personal information we collected</h2>
      <div>
        Our objective for collecting personal information is to provide our
        services to you. In addition, those information is also used to ensure
        we follow the applicable laws and regulations. This includes:
      </div>
      <ol>
        <li>Providing services to you</li>
        <li>Verifying your identity</li>
        <li>Preventing fraud</li>
        <li>Protecting and securing the system</li>
        <li>Providing relevant promotion</li>
        <li>Offering personalized services and contents</li>
        <li>Diagnosing and fixing system errors</li>
        <li>Providing push notification service</li>
        <li>For marketing and advertising purposes</li>
        <li>Improving user experience</li>
      </ol>
    </Section>
    <Section>
      <h2>5. How we share personal information to third-party partners</h2>
      <div>
        We do not sell your personal information to third-party providers. We
        sometimes need to share your personal information with third-party
        partners to provide or improve our product or services.
      </div>
      <div>
        <h3>Our Group Companies</h3>
        <div>
          We can share your personal information from time to time to affiliates
          of HOKI Shop Taiwan.
        </div>
        <h3>Third-party Providers and Business Partners</h3>
        <div>
          We may use third party companies or individuals in providing our
          services. If needed, we will share your personal information to our
          service providers or business partners. We ensure that your
          information is shared when it is necessary and that the reason is
          legitimate, specific, and important in order to provide services to
          you.
        </div>
        <div>
          To perform analysis and measurement of your performance and services,
          we may provide non-personal information with third parties. This
          information is used to help either us or third parties in performing
          the aforementioned measurement and analysis tasks.
        </div>
        <div>
          We are not responsible for the privacy policies of other organization.
        </div>
      </div>
    </Section>
    <Section>
      <h2>6. Security</h2>
      <div>
        We are aware of the importance of your personal information. Because of
        that, we implement appropriate procedures to protect your personal
        information. To secure your connection to us, we use Transport Layer
        Security (TLS)/Secure Sockets Layer (SSL) technology. Please ensure that
        your connection is encrypted through TLS/SSL so that your data is
        transmitted and received securely. All of your personal information is
        stored in servers with access control to prevent unauthorized access.
      </div>
      <div>
        However, it is important to remember that there are no perfect secure
        system. If you feel that your account security is compromised, you can
        inform us by contacting us according to the 'Contact Us' section.
      </div>
    </Section>
    <Section>
      <h2>7. Retention Period</h2>
      <div>
        We may retain your personal information for the required amount of time
        to fulfill the objectives outlined in this Policy and according to
        applicable laws and regulations.
      </div>
    </Section>
    <Section>
      <h2>8. Data Deletion Request</h2>
      <div>
        If you request us to delete your data, we will also required to close
        your account. Your data will then either be deleted or anonymized so
        that it will no longer identifies you. Note that this means you will
        lose access to your account and all data associated with your account
        (such as purchase history) unless you back it up in advance.
      </div>
      <div>
        However, in some cases, we are required to keep some of your personal
        data for legally justifiable reasons. For example:
        <ul>
          <li>
            There is an unresolved issue with your account, such as outstanding
            amount or unresolved dispute
          </li>
          <li>For our legal, tax, or accounting obligations</li>
          <li>For fraud prevention</li>
        </ul>
      </div>
      <div>
        To request data deletion, you can either contact us through the
        information outlined in the 'Contact Us' section, or by sending an email
        to app-support@xinchuan.tw.
      </div>
    </Section>
    <Section>
      <h2>9. Information from Underage Children</h2>
      <div>
        We do not offer direct services to underage children. In addition, we
        knowingly do not collect information from underage children. If we
        discover that we have collected information from underage children, we
        will delete that information and stop providing service to the child.
      </div>
    </Section>
    <Section>
      <h2>10. Changes to this Policy</h2>
      <div>
        We may change this Policy at any time. Every changes can be seen in this
        page. Changes to Policy applies from the moment the changes is published
        in this page. We recommend that you check this page periodically to see
        whether there are changes to this Policy.
      </div>
      <div>
        Below are the dates where there are significant changes to this Policy.
        <ul>
          <li>09 October 2021.</li>
          <li>
            17 February 2021. Added section outlining significant changes to
            this Policy and added section explaining which Policy language
            version prevails.
          </li>
          <li>22 October 2019. Initial version of this Policy.</li>
        </ul>
      </div>
    </Section>
    <Section>
      <h2>11. Prevailing language</h2>
      <div>
        This Policy may be translated and executed in multiple languages other
        than Indonesian ("Bahasa Indonesia") which taken together is considered
        as a single Policy. If there are any conflict or difference in meaning
        in the translated Policy, the Indonesian language version will prevail.
      </div>
    </Section>
    <Section>
      <h2>12. Contact Us</h2>
      <div>
        If you have any comment, complaint, suggestion, or question about this
        Policy, you can contact us at:
      </div>
      <div>
        <h3>Address</h3>
        <div>
          高雄市新興區八德一路308號4樓
          <br />
          Bade 1st Road No. 308. 4th Floor.
          <br />
          Xinxing District
          <br />
          Kaohsiung City
          <br />
          Republic of China (Taiwan)
        </div>
      </div>
      <div>
        <h3>Phone</h3>
        <div>+886 970 624 000</div>
      </div>
    </Section>
  </Base>
)

export default PrivacyPolicyPage
